@value white, dark-grey from '../../../../assets/styles/variables/colors.css';
@value ekgNormal, ekgAbnormal, ekgOther, ekgNoAnalysis, stable, mdn from '../../../../assets/styles/ekgColors.css';
@value bmiNormal, bmiUnderweight, bmiOverweight, bmiObese from '../../../../assets/styles/bmiColors.css';
@value bpNormal, bpPrehypertension, bpHypertension1, bpHypertension2, bpHypertensiveCrisis from '../../../../assets/styles/bpColors.css';
@value chartFontSize 12px;
@value circleDiameter 12px;

.legend {
  position: absolute;
  padding-top: 8px;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  width: 100%;
}
.legendItem {
  font-size: chartFontSize;
  color: dark-grey;
  display: inline-block;
  margin: 0 25px;
}
.legendItem svg {
  vertical-align: bottom;
  height: 12px;
  width: 12px;
}
.countText {
  padding-left: 4px;
}

.circle {
  stroke: #fff;
  stroke-width: 1px;
}
