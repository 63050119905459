@value teal, light-teal, white, black, silver, dark-white, lightest-grey, lighter-grey, light-grey, medium-grey from '../../assets/styles/variables/colors.css';
@value a, h2, lbl from '../../assets/styles/typography.css';
@value sidebarWidth, sidebarCollapsedWidth from '../../assets/styles/variables/global.css';
@value headerHeight from '../../assets/styles/page.css';

.sidebar {
  z-index: 50;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: sidebarWidth;
  max-width: sidebarWidth;
  background-color: white;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  transition: max-width 250ms;
}

.mainLogo {
  min-height: headerHeight;
  display: block;
  position: relative;
}
.mainLogo svg {
  width: 150px;
  height: 30px;
  fill: black;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -32px;
  right: 0;
  cursor: pointer;
}

.mainLogo span svg {
  width: 0.5em;
  height: 1em;
  fill: #9e9c9ccc;
  margin: auto;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 170px;
  right: 0;
}

.mainLogoCollapsed {
  min-height: 110px;
  display: block;
  position: relative;
}
.mainLogoCollapsed svg {
  width: 1em;
  height: 1em;
  fill: black;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
.profile {
  position: relative;
  min-height: 168px;
  padding: 0 16px 0;
  text-align: center;
  border-top: 1px solid lighter-grey;
}
.profileCollapsed {
  min-height: 80px;
}
.buildInfo {
  margin: 16px;
  font-size: 14px;
  color: medium-grey;
}
.avatar {
  opacity: 1;
  display: inline-block;
  width: 68px;
  height: 68px;
  border-radius: 100%;
  margin: 24px auto 4px;
}
.avatarCollapsed {
  composes: avatar;
  width: 40px;
  height: 40px;
  margin: 24px 7px 4px;
}
.initials {
  width: 68px;
  height: 68px;
  background-color: dark-white;
  border: 1px solid lightest-grey;
  border-radius: 68px;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  margin: 24px auto 4px;
}
.initialsCollapsed {
  composes: initials;
  width: 40px;
  height: 40px;
}
.initials span {
  opacity: 1;
  background-color: teal;
  height: 54px;
  width: 54px;
  display: block;
  border-radius: 54px;
  margin: 6px 0 0 6px;
  padding: 13px 0;
  line-height: 28px;
}
.initialsCollapsed span {
  opacity: 1;
  background-color: #2d9f86;
  height: 30px;
  width: 30px;
  display: block;
  border-radius: 30px;
  margin: 5px 0 0 5px;
  font-size: 14px;
  /* padding: 13px 0; */
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userName {
  composes: h2;
  font-size: 18px;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Work Sans;
  font-weight: 700;
}
.teamName {
  composes: lbl;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: silver;
  line-height: 1.2;
  font-family: Work Sans;
  margin-top: 0;
}
.logoWrapper {
  margin-right: 16px;
}
.logo {
  height: 48px;
  width: 48px;
}
.navList {
  /*min-height: 360px;*/
  padding: 0px;
}
.navList > li:hover {
  background-color: #e6e6e6;
}
.navList > li:first-child .sidebarItem {
  border-top: 1px solid lighter-grey;
}
.navList .spacing {
  border-top: 1px solid lighter-grey;
}

.navList > li:last-child .sidebarItem,
.navList > li:last-child .navExtLink {
  border-bottom: 1px solid lighter-grey;
}
.navExtLink,
.sidebarItem {
  height: 48px;
  display: block;
  width: 100%;
  color: black;
  /*border-bottom: 1px solid lighter-grey;*/
}
.navExtLink:hover,
.sidebarItem:hover {
  font-weight: bold;
  color: black;
}
.navExtLink:hover .navIcon svg,
.sidebarItem:hover .navIcon svg {
  fill: teal;
  /*stroke: teal;*/
}
.sidebarItemActive {
  /* pointer-events: none; */
  font-weight: bold;
}
.sidebarItemActive .navIcon svg {
  /*fill: teal;*/
  /*stroke: teal;*/
}
.sidebarItemActiveOverride {
  composes: sidebarItem;
  font-weight: bold;
}
.listItemWithChildren {
  position: relative;
}
.listItemWithChildren > .sidebarItem::before {
  border-style: solid;
  border-color: silver;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 5px;
  right: 25px;
  position: absolute;
  top: 34px;
  transform: rotate(45deg);
  vertical-align: top;
  width: 5px;
}
.listItemWithChildren > .sidebarItemActive::before {
  transform: rotate(135deg) !important;
}
.listItemWithChildren .sidebarItemActive {
  /*border-bottom: none;*/
}
.listItemWithChildren .sidebarItemActive + .navListLevelTwo {
  display: block;
  border-bottom: 1px solid lighter-grey;
}
.navListLevelTwo {
  display: none;
  margin-top: -10px;
}
.navListLevelTwo li:last-child .sidebarItemLevelTwo {
  padding-bottom: 23px;
  height: 51px;
}
.sidebarItemLevelTwo {
  composes: sidebarItem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: silver;
  /*border-bottom: none;*/
  height: 28px;
  /*border-left: 1px solid lighter-grey;*/
  margin-left: 79px;
}
.sidebarItemLevelTwo:hover {
  color: teal;
  font-weight: bold;
}
.sidebarItemLevelTwo .bullet {
  width: 6px;
  height: 6px;
  border: 1px solid light-teal;
  border-radius: 50%;
  margin-left: -25px;
  transition: margin 0.2s;
}
.sidebarItemLevelTwo .navText {
  padding-left: 42px;
}
.sidebarItemLevelTwoActive .bullet {
  background-color: light-teal;
  margin-left: -15px;
}
.sidebarItemLevelTwo:hover .bullet {
  background-color: light-teal;
  margin-left: -15px;
}
.sidebarItemLevelTwoActive {
  color: teal;
  pointer-events: none;
  font-weight: bold;
}
.leftGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navIcon {
  display: inline-block;
  /*border-right: 1px solid lighter-grey;*/
  width: 72px;
  height: 48px;
}
.navIcon svg {
  display: inline-block;
  fill: light-teal;
  /*stroke: light-teal;*/
  width: 24px;
  height: 24px;
  display: block;
  margin: auto 16px;
  height: 100%;
}
.navText {
  font-size: 15px;
  /*padding-left: 23px;*/
  white-space: nowrap;
  transition: padding 0ms;
  user-select: none;
  font-family: Work Sans;
}
.navListLevelTwo .navText {
  font-size: 14px;
}
.sidebarFooter {
  margin: auto 0 100px;
  min-height: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.sidebarFooterItem:hover {
  background-color: #e6e6e6;
}
.settings {
  composes: sidebarItem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: none;
  height: 50px;
}
.settings .navIcon,
.logout .navIcon {
  border-right: none;
  height: 50px;
}
.settings .navIcon svg,
.logout .navIcon svg {
  margin-right: 0;
}
.logout {
  composes: sidebarItem;
  composes: a;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border: none;
  outline: none;
  padding: 0;
  height: 50px;
}
.logout:hover {
  background-color: #e6e6e6;
}

.sidebarCollapsed {
  composes: sidebar;
  min-width: sidebarCollapsedWidth;
  max-width: sidebarCollapsedWidth;
}

.sidebarCollapsed .navText {
  padding-left: 32px;
  transition: padding 0ms linear 200ms;
}

.loader {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
}

.navExtLink {
  color: inherit;
}
.navExtLink:hover {
  font-weight: bold;
  color: black;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 24px;
  border-radius: 20px;
  color: white;
  background-color: teal;
  font-size: 12px;
  margin-right: 10px;
  padding: 10px;
  line-height: 0px;
}
