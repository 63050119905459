@value tableGridRow, medium-grey, light-grey, lighter-grey, dark-white, white, black, lighter-teal, lightest-teal, light-teal, teal, tableGridGrey, darker-grey, silver from '../../../../assets/styles/variables/colors.css';
@value ekgNormal, ekgAbnormal, ekgOther, ekgNoAnalysis from '../../../../assets/styles/ekgColors.css';
@value bpNormal, bpPrehypertension, bpHypertension1, bpHypertension2, bpHypertensiveCrisis from '../../../../assets/styles/bpColors.css';
@value bmiNormal, bmiUnderweight, bmiOverweight, bmiObese from '../../../../assets/styles/bmiColors.css';
@value mainFontFamily from '../../../../assets/styles/variables/global.css';

.table {

}

.loadingOverlay {
  position: absolute;
  top: 94px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.noDataOverlay {
  position: absolute;
  top: 94px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.noDataText {
  padding-top: 132px;
  text-align: center;
  color: silver;
  font-family: mainFontFamily;
  font-size: 32px;
  font-weight: 600;
}
:global(.ReactVirtualized__Table__headerRow) :global(.ReactVirtualized__Table__headerColumn):first-child > *,
:global(.ReactVirtualized__Table__headerRow) :global(.ReactVirtualized__Table__headerColumn):first-child > * > *:first-child {
  border-top-left-radius: 4px;
}
:global(.ReactVirtualized__Table__headerRow) :global(.ReactVirtualized__Table__headerColumn):last-child > *,
:global(.ReactVirtualized__Table__headerRow) :global(.ReactVirtualized__Table__headerColumn):last-child > * > *:first-child {
  border-top-right-radius: 4px;
}

.column {
  border-right: 1px solid tableGridGrey;
  height: inherit;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* flex-direction: column; */
}

.table .column:last-of-type {
  border-right: none;
}

.columnContent {
  font-size: 14px;
  font-weight: 500;
  color:black;
  font-family: 'Work Sans';
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  white-space: break-spaces;
}
.centeredColumnContent {
  composes: columnContent;
  align-items: center;
}

.number {
  font-family: 'anonymous-pro';
  font-size: 14px;
  font-weight: 400;
  color: darker-grey;
}

.centeredText {
  text-align: center;
}

.primaryText {
  /* font-size: 14px;
  font-weight: 600;
  font-family: 'proxima-nova'; */
  font-family: 'Work Sans';
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.secondaryText {
  margin: 3px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding-left: 1px;
}

.headerRow {
  /* unfortunately have to use important to override third party inline styles */
  overflow: visible !important;
  padding-right: 0 !important;
  display: flex;
  height: 50px !important
}
.row {
  /* unfortunately have to use important to override third party inline styles */
  padding: 0 !important;

  border-bottom: 1px solid tableGridGrey;
  color: black;
  font-size: 14px;
  font-weight: 600;
  font-family: 'proxima-nova';
  outline: none;
  display: flex;
}

.clickableRow {
  cursor: pointer;
}

.row:last-child {
  border-bottom: none;
  border-right: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.evenRow {
  background-color: dark-white;
}
.oddRow {
  background-color: white;
}
.row:hover {
  background-color: lighter-teal;
}
/* using css hover for now until we add actions to the hover */
.hoveredRow {
  /*background-color: lighter-teal; */
}

.headerWrapper {
  /* box-shadow: 0px 0px 3px 0px light-grey; */
}
.tableBody {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  /* box-shadow: 0px 2px 0px 0px light-grey; */
  background-color: dark-white;
  outline: none;
}
.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.headerColumn.disableRowSelection .checkbox,
.column.disableRowSelection .checkbox {
  cursor: not-allowed;
}

.headerColumn.disableRowSelection .checkbox rect,
.column.disableRowSelection .checkbox rect {
  fill: transparent;
  stroke: medium-grey;
  stroke-width: 1px;
}

.headerColumn.disableRowSelection .checkbox path,
.column.disableRowSelection .checkbox path {
  fill: medium-grey;
}

.checkbox input[type=checkbox] {
  display: none;
}

.checkboxIconUnchecked {
  height: 20px;
  width: 20px;
}

.checkboxIconChecked {
  height: 20px;
  width: 20px;
}

.rowWithColors > div:first-child {
  border-left-color: inherit;
}
.rowWithColors > div:first-child > div {
  border-left-color: inherit;
  border-left-style: solid;
  border-left-width: 4px;
}

.determinationPopOver {
  width: 20px;
  color: #d5d3d3;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 5px;
  /* display: none; */
  display: block;
}

.columnContent:hover + .determinationPopOver{
  display: block;
}

.popoverToolTipContent {
  margin: 0px 20px 0px 20px;
  width: 240px;
  height: 78px;
  word-break: break-word;
  font-weight: normal;
  line-height: 20px;
}
