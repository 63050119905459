@value light-grey from '../../assets/styles/variables/colors.css';

@value contentWidth from '../../assets/styles/variables/global.css';

.pageTabs {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
  margin: 10px 0 0;
  /* margin: 0 auto 12px; */
  /* width: calc(contentWidth - (55px * 2)); */
  /* width: contentWidth; */
  /* border-bottom: 1px solid light-grey; */
}
