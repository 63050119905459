@value sidebarWidth, sidebarCollapsedWidth from '../../assets/styles/variables/global.css';
@value bg-white from '../../assets/styles/variables/colors.css';

.root {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100vh;
}

.content {
  width: calc(100% - sidebarWidth);
  background-color: bg-white;
  overflow-x: hidden;
  overflow-y: scroll;
}
.contentWithSidebarCollapsed {
  composes: content;
  width: calc(100% - sidebarCollapsedWidth);
}
