@value teal, lighter-teal,kardia-grey from '../../assets/styles/variables/colors.css';

.chatBody {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.chatBody::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 26px;
  font-size: 12px;
}
.messageLeft {
  composes: message;
  flex-direction: row;
}

.messageRight {
  composes: message;
  flex-direction: row-reverse;
}
.userimg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #a582bb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  margin-right: 12px;
}
.messageContainer {
  display: flex;
}
.messageInnerContainer {
  border-radius: 10px;
  padding: 10px 15px;
  max-width: 500px;
  margin: 0 !important;
  word-wrap: break-word;
  font-weight: normal;
  align-self: center;
}
.messageInnerContainerLeft {
  composes: messageInnerContainer;
  background-color: #babfbd25;
  border: 1px solid #babfbd25;
}
.messageInnerContainerRight {
  composes: messageInnerContainer;
  background-color: lighter-teal;
  border: 1px solid lighter-teal;
}
.messageContent {
  font-family: Work Sans;
  font-size: 13px;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.messageTimestamp {
  font-family: Work Sans;
  bottom: 5px;
}

.messageTimestampRight {
  composes: messageTimestamp;
}

.messageTimestampLeft {
  composes: messageTimestamp;
}
.inputFieldContainer {
  display: flex;
  border-radius: 2em;
  /* padding: 5px; */
  background-color: #babfbd25;
}
.messageHeader {
  font-family: Work Sans;
  text-align: center;
  margin: 26px auto;
}

.sendButton {
  color: white !important;
  /* background-color: teal !important;
  border-radius: 2em !important; */
  padding: 10px !important;
}

.imgBox {
  line-height: 0;
  max-height: 336px;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.imgBox > img {
  background-color: #2021240a;
  padding: 18px;
  border-radius: 4px;
  background-size: 0;
  box-sizing: border-box;
  display: inline-block;
  max-height: 336px;
  max-width: 100%;
  text-indent: -9999px;
}

/* //message box styles when file is encountered */
.imageMessageInnerContainer {
  position: relative;
}
.downloadIcon {
  position: absolute;
  top: 8px;
  right: 5px;
  z-index: 1;
  cursor: pointer;
  background-color: black;
  color: azure;
  font-size: 35px !important;
  font-weight: 900;
  border-radius: 50%;
  padding: 5px;
}
/* // errro style for any file upload error */
.fileUploadError {
  font-size: 14px;
  color: red;
}

/* Chrome, Safari, AND NOW ALSO the Edge Browser and Firefox */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  audio {
    min-width: 320px;
    width: 100%;
    display: block;
    font-variant: full-width;
  }
}
