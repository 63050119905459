.selectStyle {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
  width: 100%;
  padding: 10px;
  margin: 10px 0 20px;
  border: 2px solid lightgrey;
  border-radius: 5px;
  outline: none;
}

textarea::placeholder {
  color: #142a39;
}