@value kardia-green, kardia-yellow, kardia-orange, kardia-red, kardia-red-crisis from './variables/colors.css';

.bpNormal {
  fill: kardia-green;
  border-left-color: kardia-green;
}
.bpPrehypertension {
  fill: kardia-yellow;
  border-left-color: kardia-yellow;
}
.bpHypertension1 {
  fill: kardia-orange;
  border-left-color: kardia-orange;
}
.bpHypertension2 {
  fill: kardia-red;
  border-left-color: kardia-red;
}
.bpHypertensiveCrisis {
  fill: kardia-red-crisis;
  border-left-color: kardia-red-crisis;
}
