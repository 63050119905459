.telekardia {
  @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;700&display=swap');

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    font-family: 'Work Sans' !important;
    word-wrap: break-word !important;
    //word-break: break-word;
  }
  body {
    background: #f5f5f5;
    font-size: 14px;
    font-family: 'Work Sans' !important;
    font-weight: 400;
    color: #142a39;
    word-wrap: break-word !important;
    //word-break: break-word;
  }
  /*li {*/
  /*  word-wrap: break-word !important;*/
  /*  word-break: break-word;*/
  /*}*/
  //.right_container {
  //  min-width: 1240px;
  //}
  .home_nav {
    width: 450px;
    margin: 0 auto;
    background: #fff;
  }
  .home_nav a {
    font-family: 'Work Sans' !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #142a39;
    opacity: 0.54;
    height: 48px;
    border-radius: 0 !important;
  }
  h6 {
    font-family: 'Work Sans' !important;
    font-weight: 500;
    font-size: 20px;
    color: #536063;
    letter-spacing: 0.25px;
  }
  .home_nav_tablet {
    flex-grow: 1;
    background: #fff;
  }
  .name_caption {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }
  .name_smallcaption {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }
  .f10 {
    font-size: 10px !important;
  }
  .f400 {
    font-weight: 400;
  }
  .f500 {
    font-weight: 500;
  }
  .f600 {
    font-weight: 600;
  }
  .f700 {
    font-weight: 700;
  }

  .nav-pills .nav-link.active {
    background: #fff;
    color: #2d9f86;
    opacity: 1;
    border-bottom: 4px solid #2d9f86;
  }
  .calendar-container {
    margin: 0 auto;
    padding: 0px;
  }
  .btn.focus,
  .btn:focus {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }
  /*popover*/
  .popover {
    box-shadow: 1px 1px 6px 0 #0000003d;
    -moz-box-shadow: 1px 1px 6px 0 #0000003d;
    -webkit-box-shadow: 1px 1px 6px 0 #0000003d;
    min-width: 300px !important;
    max-width: 400px !important;
    padding: 15px;
  }
  .close_icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .popover .close_icon svg {
    fill: #fff;
  }
  .set_bg {
    background: #cccccc2b;
  }

  /*.rbc-event.rbc-selected {*/
  /*    background: #F78C4E !important;*/
  /*    left: 0 !important;*/
  /*    display: flex;*/
  /*    flex-flow: column-reverse;*/
  /*}*/

  /*React big calendar overwrite Ends here*/

  // .MuiSvgIcon-root {
  //   position: relative;
  //   /*top: -2px;*/
  //   width: 0.75em !important;
  //   height: 0.75em !important;
  // }
  .btn_hover {
    padding: 4px 10px;
  }
  .btn_hover:hover {
    background: #cccccc40;
    text-decoration: none;
  }
  .popover-header {
    color: #495057;
    text-transform: inherit;
    background: none;
    border: 0px;
  }
  .close_icon {
    color: #495057;
  }

  /*Navbar*/
  .navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:focus,
  .navbar-default .navbar-nav > .active > a:hover {
    color: #555;
    background-color: #e7e7e7;
  }
  .navbar-nav > li {
    line-height: 20px;
    position: relative;
    display: block;
    padding: 10px 15px;
  }
  .user_image {
    background: #cccccc52;
    width: 80px !important;
    height: 70px !important;
  }
  .user_row {
    background: #fff;
    border: 1px solid #cccccc59;
    border-left: 5px solid #2d9f86;
  }
  .f60 {
    font-size: 60px !important;
    background: #cccccc52;
    width: 50px;
    height: 50px;
    margin: 10px;
    padding: 10px;
    color: #2d9f86;
  }
  .w100 {
    width: 100%;
  }
  .tab-content {
    background: #fff;
    width: 100%;
    border: 1px solid #ddd;
    border-top: 0;
  }
  .minheight200 {
    min-height: 200px;
  }
  .btn_white a {
    color: #fff !important;
  }

  /*overwrite design*/
  .apptlist_head {
    font-size: 0.8rem;
    color: #2a4661;
  }
  .apptlist_subhead {
    font-size: 65% !important;
    font-weight: normal !important;
  }
  .list-group-item {
    border: 1px solid #b7c4d2 !important;
  }
  .badge-primary {
    color: #fff;
    background-color: #634fd1 !important;
  }

  .avatar {
    width: 45px;
    height: 45px;
    overflow: hidden;
  }

  .avatar img {
    width: 100%;
    height: 100%;
  }
  .avatar_big {
    width: 50px;
    height: 50px;
    overflow: hidden;
  }
  .avatar_big img {
    width: 100%;
    height: 100%;
  }
  .opacity-5 {
    opacity: 0.5;
  }
  .l_1 {
    line-height: 1;
  }
  .l_15 {
    line-height: 1.5em;
  }
  .line16 {
    line-height: 16px;
  }
  .text-secondary {
    color: #9999998c !important;
  }
  .spl_txt {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .f70 {
    font-size: 70% !important;
  }
  .f80 {
    font-size: 80% !important;
  }
  .f90 {
    font-size: 90% !important;
  }
  .f100 {
    font-size: 100% !important;
  }
  .border_1 {
    border: 1px solid #ccc;
  }
  .card-body {
    padding: 0.8rem !important;
  }
  .card {
    border-radius: 0 !important;
  }

  .Dashboard_right .MuiTabs-root {
    min-height: 40px !important;
  }

  .Dashboard_right .MuiAppBar-colorDefault,
  .MuiAppBar-colorDefault {
    background-color: #fff !important;
    box-shadow: none !important;
  }
  .Dashboard_right .MuiPaper-elevation4 {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }
  button:focus {
    outline: 0px auto -webkit-focus-ring-color !important;
  }
  .custom_popover .popover_title {
    height: auto !important;
    color: #ffffff;
    /* font-size: medium; */
  }

  .custom_popover .popover_title .month {
    height: auto !important;
  }

  .custom_popover .popover_title span {
    color: #ffffff !important;
    padding-left: 5px;
    position: relative;
  }

  .custom_popover .popover_title span.countav {
    font-size: 12px !important;
    padding-left: 19px;
  }

  .custom_popover .popover_title span.countap {
    font-size: 12px !important;
    padding-left: 57px;
  }
  .custom_popover .popover_title span.avail {
    font-size: 11px !important;
    padding-left: 4px;
  }
  .custom_popover .popover_title span.app {
    font-size: 11px !important;
    padding-left: 29px;
  }
  .custom_popover .popover_title span.hours {
    font-size: 11px !important;
    padding-left: 22px;
  }
  .custom_popover .popover_title span.planned {
    font-size: 11px !important;
    padding-left: 45px;
  }

  .custom_align {
    margin-top: -6px;
  }

  .custom_popover {
    padding: 5px;
  }
  .show.popover {
    background: rgba(249, 249, 249, 0.78);
  }
  .popover .close_icon svg {
    fill: #495057;
  }
  .custom_btn {
    padding: 0.175rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
  }
  .minw {
    min-width: 80px;
  }

  /*swal overwrite */
  .swal-text {
    color: #142a39;
  }
  .swal-button {
    text-transform: uppercase;
    padding: 0px 20px;
  }
  .swal-button:focus {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  .swal_cancel,
  .swal_ok {
    font-size: 14px;
    font-weight: 700;
    background: transparent !important;
  }
  .swal_cancel {
    color: #142a39;
  }
  .swal_ok {
    color: #2d9f86;
  }
  .swal_close {
    border: 0 !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    position: fixed;
    top: 5px !important;
    right: 0 !important;
    padding: 0;
    margin: 0;
    min-width: 50px !important;
    color: #536063 !important;
    right: 32px;
    top: 32px;
    width: 22px;
    height: 32px;
  }
  .swal-title,
  .swal-text,
  .swal-footer {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .swal_close:before,
  .swal_close:after {
    position: absolute;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #333;
    left: 18px;
    top: 10px;
  }
  .swal_close:after {
    transform: rotate(-45deg);
  }
  .swal_close:before {
    transform: rotate(45deg);
  }
  /*swal overwrite */

  .btn_grey {
    background: #e2e7e3 !important;
    color: #142a39 !important;
    font-size: 14px !important;
  }
  .btn_teal {
    background: #067f6f !important;
    color: #ffffff !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    letter-spacing: 0.75px;
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .btn_teal_arrow {
    color: #067f6f !important;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
  .btn_grey.selected {
    background: #142a39 !important;
    color: #fff !important;
  }

  /*modal window overwrite*/
  .btn {
    font-size: 14px;
    font-weight: 700;
    min-height: 40px;
    letter-spacing: 0.75px;
  }
  .h40 {
    height: 40px;
  }
  .modal .btn-primary {
    color: #fff;
    background-color: #536063 !important;
    border-color: #536063 !important;
  }
  .width_90 {
    width: 90px;
  }

  .modal-title {
    font-size: 24px !important;
  }
  .minheight_200 {
    height: 200px;
  }
  /*end*/
  .width_180 {
    width: 180px !important;
  }
  .width_180 .MuiGrid-root.MuiGrid-container,
  .width_180 .MuiFormControl-root.MuiTextField-root {
    width: 180px !important;
  }
  .width_180.align_right .MuiGrid-root.MuiGrid-container,
  .width_180.align_right .MuiFormControl-root.MuiTextField-root {
    float: right !important;
  }

  .width_180.align_left .MuiGrid-root.MuiGrid-container,
  .width_180.align_left .MuiFormControl-root.MuiTextField-root {
    float: left !important;
  }
  .op_6_icon .MuiIconButton-label {
    opacity: 0.6;
  }

  /*material date and time picker color change*/

  .MuiPickersToolbar-toolbar,
  .MuiPickersClock-pin,
  .MuiPickersClockPointer-pointer,
  .MuiPickersClockPointer-noPoint,
  .MuiPickersDay-daySelected {
    background-color: #2d9f86 !important;
  }
  .MuiPickersClockPointer-thumb {
    border-color: #2d9f86 !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #2d9f86 !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2d9f86 !important;
  }
  /*end*/

  .MuiTabs-flexContainerVertical {
    width: 420px;
  }
  .MuiTypography-body1 {
    font-size: 14px;
  }

  .border-0 {
    border: 0 !important;
  }
  .img_cont {
    width: 50px;
    height: 50px;
    background: #adadad;
    border-radius: 50%;
  }
  .f80 {
    font-size: 80%;
  }

  /*.src-legacy-layouts-ContentWithNavigationSidebar-ContentWithNavigationSidebar__content--mWDL1{*/
  /*  overflow-y: auto !important;*/
  /*}*/
  .shade {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
  }
  .bg_white {
    background: #fff;
  }
  h5.head {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }
  .dashboard_heads {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }
  .caption20 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  .caption20small {
    font-weight: 500;
    font-size: 24px;
  }

  h5.heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    letter-spacing: 0.15px;
  }
  .subhead {
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    color: #142a39;
  }
  .font_title,
  .bs-stepper-label {
    font-weight: 700;
    font-size: 14px;
    color: #142a39;
  }
  .font_med {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  .font_grey {
    color: #142a39;
    opacity: 0.8;
  }
  .home_center {
    width: calc(100% - 330px);
    /*min-height:calc(100%);*/
    /*height: calc(100vh - 20px);*/
    /*height:fit-content;*/
    overflow-y: auto;
    min-height: 100%;
    /*padding-top:100px;*/
  }
  .contacthead {
    font-weight: 700;
    font-size: 20px !important;
    line-height: 3px !important;
    color: #142a39;
  }
  .home_left {
    background: #fff;
  }
  hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border: 0;
    border-top: 2px solid #0000001f;
  }
  .hr_1 {
    width: 82px;
    height: 0px;
    left: 163px;
    top: 294px;

    /* ◇ Light / Teal (Primary) */

    border: 2px solid #2d9f86;
  }
  .hr_2 {
    width: 159px;
    height: 0px;
    left: 163px;
    top: 294px;

    /* ◇ Light / Teal (Primary) */

    border: 2px solid #2d9f86;
  }
  .hr_3 {
    width: 155px;
    height: 0px;
    left: 163px;
    top: 294px;

    /* ◇ Light / Teal (Primary) */

    border: 2px solid #2d9f86;
  }
  .f600 {
    font-weight: 600;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
  .f18 {
    font-size: 18px;
  }
  .caption {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.15px;
    color: #142a39;
  }
  .section_head {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.15px;
    color: #142a39;
  }
  .text_head {
    font-weight: 700;
    font-size: 16px;
    color: #000;
    line-height: 28px;
  }
  .font_green {
    color: #2d9f86 !important;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 !important;
  }
  .MuiButtonBase-root.Mui-selected .MuiTab-wrapper {
    color: #2d9f86 !important;
  }
  .iconset .MuiSvgIcon-root {
    fill: #babfbd;
    font-size: 1.7rem;
    position: relative;
    top: 0px;
  }
  .icon_large .MuiSvgIcon-root {
    font-size: 2.5rem;
  }
  .time_content {
    font-weight: 600;
  }

  .rbc-time-slot .rbc-label {
    padding: 0 5px;
    font-size: 20px;
    color: #536063;
    font-weight: 400;
    position: relative;
    top: 15px;
  }
  h5.count {
    font-size: 34px;
    font-weight: 500;
  }

  /*apptdetails*/
  .height_100 {
    min-height: 100px;
  }
  .height_500 {
    min-height: 500px;
  }
  .height_300 {
    min-height: 300px;
    max-height: 300px;
    overflow-y: auto;
  }
  .height_800 {
    min-height: 800px;
  }
  .height_815 {
    min-height: 815px;
  }
  .height_800 {
    height: 800px;
    overflow-y: auto;
  }
  .height460 {
    height: 460px;
    overflow-y: auto;
  }
  .heightaudiozero {
    height: 0px;
    overflow-y: hidden;
  }
  .height720 {
    height: 720px;
    overflow-y: auto;
  }
  .heigh500video {
    height: 500px;
    overflow-y: auto;
  }
  .height_960 {
    min-height: 960px;
  }
  .height_417 {
    height: 417px;
    overflow-y: auto;
  }
  .height_400 {
    height: 400px;
    overflow-y: auto;
  }
  .height_484 {
    min-height: 484px;
  }
  .height_280 {
    height: 280px !important;
  }
  .height_381 {
    height: 381px;
  }
  .height_335 {
    height: 335px;
  }
  .height_240 {
    height: 240px !important;
  }
  .height_230 {
    height: 230px !important;
  }
  .fullheight_mynotes_textarea {
    height: 100vh !important;
  }
  .height_600_mynotes_expandvideo {
    height: 600px !important;
  }
  .height_457 {
    height: 457px;
  }
  .height_280 {
    height: 280px;
  }
  .height_530 {
    height: 530px;
  }
  .minheight_280px {
    min-height: 280px;
  }
  .bs-stepper {
    position: relative;
  }
  .bs-stepper-header {
    width: 472px !important;
    // width:calc(100% - 300px)
  }
  .timer {
    width: 75px;
    height: 75px;
  }
  .timer {
    background: #e6c029;
    font-size: 40px;
    line-height: 75px;
    color: #fff;
  }
  .limelimit {
    position: absolute;
    top: 0px;
    right: 0;
  }
  .kardiaprotitle {
    position: absolute;
    width: 200px;
    height: 28px;
    left: 0px;
    top: 35px;
    font-family: 'Work Sans' !important;
    font-style: normal;
    // font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.002em;
    color: #142a39;
  }
  .bg_black {
    background: #000;
  }
  .bg_grey {
    background: #f2f4f8;
  }
  .font_white {
    color: #fff;
  }

  .font_darkgray {
    font-family: 'Work Sans' !important;
    color: #536063;
  }

  .MuiDialog-container .MuiDialog-paperScrollPaper {
    background: #f2f4f8 !important;
  }
  .MuiDialog-container .MuiDialogTitle-root button .MuiSvgIcon-root {
    fill: #142a39 !important;
    font-size: 2.4rem !important;
  }
  .MuiDialogTitle-root .MuiTypography-h6 {
    color: #000;
  }
  .EKG_canvas {
    overflow: auto;
    height: calc(100% - 55px);
    padding: 5px;
  }
  .EKG_canvas div,
  .EKG_canvas canvas {
    // width:100% !important;
  }
  .EKG_canvas_non6L {
    overflow: auto;
    height: calc(100% - 55px);
    padding: 5px;
  }
  .EKG_canvas_non6L div,
  .EKG_canvas_non6L canvas {
    width: 100% !important;
  }
  .radius8 {
    border-radius: 8px;
  }
  /*steper*/
  .bs-stepper .step-trigger {
    padding: 0 !important;
    width: 135px !important;
    white-space: normal !important;
  }
  .active .bs-stepper-circle {
    background-color: #2d9f86 !important;
    opacity: 1 !important;
  }
  .bs-stepper-circle {
    background: #142a3959 !important;
    font-weight: normal !important;
    width: 30px !important;
    height: 30px !important;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 20px !important;
    font-size: 14px !important;
    border: 4px solid #fff !important;
    border-radius: 50% !important;
  }
  .bs-stepper .line,
  .bs-stepper-line {
    background-color: #0000002b !important;
    position: relative;
    top: -36px;
  }
  .bs-stepper-header {
    align-items: flex-start !important;
    margin-bottom: 40px !important;
    margin-top: 30px !important;
  }
  .bs-stepper .step-trigger:hover {
    background: transparent !important;
  }
  .bs-stepper-content {
    padding: 0 !important;
    position: relative;
  }
  .bs-stepper-header div.step {
    pointer-events: none !important;
  }
  .step .bs-stepper-label {
    font-size: 14px !important;
  }
  /*end*/
  .displayedTime .countBox {
    display: flex;
    flex-direction: row-reverse;
    border: 0px !important;
  }
  .countBoxItem .count {
    text-align: center;
    margin: 5px;
    background: #e6c029;
    border-radius: 4px;
    width: 70px;
    height: 70px;
    color: #ffffff !important;
    line-height: 70px;
    font-size: 40px;
  }
  .countBoxItem {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .countBoxItem .label {
    font-size: 14px !important;
    color: #142a39;
  }
  .recurrance_icon {
    right: 2px;
    position: absolute;
    top: 2px;
    color: #000000b8 !important;
  }
  /*.full_calendar .rbc-calendar{*/
  /*  height: calc(100vh - 170px) !important;*/
  /*}*/
  .cal_button .rbc-btn-group button {
    width: 110px;
    color: #142a39;
    font-weight: 700;
    height: 40px;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
  }
  .cal_button .rbc-btn-group:first-child button {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .cal_button .rbc-btn-group:last-child button {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .full_calendar .btn-back svg,
  .full_calendar .btn-next svg {
    width: 24px !important;
    height: 24px !important;
  }
  .max_width250 {
    max-width: 250px;
  }
  .rbc-current-time-indicator {
    height: 2px !important;
    background-color: #4fb8ff !important;
  }
  .cal_popover {
    opacity: 0.98;
  }
  .monthview_content {
    width: calc(100% - 0px);
    border-left: 1px solid #babfbd !important;
    min-width: 215px;
  }
  .bg_green {
    background: #ddf1ee;
  }
  .w176 {
    width: 176px;
    height: 176px;
    border-radius: 50%;
  }
  .hour_glass svg {
    width: 50% !important;
    height: 100% !important;
    color: #babfbd;
  }
  /*swal*/
  .swal-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    text-align: left;
  }
  .swal-text {
    font-size: 16px;
    display: block;
    padding: 0px 16px;
    line-height: 22px;
  }
  .swal_green,
  .swal_black {
    border: 1px solid #2d9f86;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px !important;
    text-transform: uppercase;
    min-width: 100px;
    background-color: transparent;
    border-radius: 5px;
    color: #fff !important;
    height: 40px;
    font-family: 'Work Sans' !important;
  }
  .swal_green {
    background: #067f6f !important;
    color: #fff;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
  }
  .swal_black {
    border: 1px solid #142a39;
    color: #142a39 !important;
  }
  .swal-button:not([disabled]):hover {
    background-color: transparent;
  }
  .text-center .swal-footer {
    text-align: center !important;
  }
  .text-center .swal-title,
  .text-center .swal-text {
    text-align: center !important;
  }
  .swal-footer {
    margin-top: 25px;
    margin-bottom: 15px;
  }
  .swal-icon--success__line {
    background-color: #6bc077;
  }
  .swal-icon--success__ring {
    border: 4px solid #6bc077;
  }
  .w-100 .MuiFormControl-root {
    width: 100%;
  }
  .customtabs_sm a {
    color: inherit !important;
  }
  .MuiCircularProgress-colorPrimary {
    color: #067f6f !important;
  }
  .spinnerContainer {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -35px;
  }
  .margin_200 {
    margin-top: -180px;
  }
  .position_rel {
    position: relative;
  }
  .label14 label {
    font-size: 18px;
    color: #2f2f2fb5;
  }
  /*header*/
  .status_txt {
    font-size: 14px !important;
  }
  .status_txt .MuiSelect-selectMenu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .status_font {
    min-width: 100px;
    max-width: 200px;
  }
  .border_rad {
    border-radius: 8px;
  }
  .height_set {
    height: calc(100% - 40px);
  }

  textarea.form-control {
    background: #f2f4f8;
  }
  .custom_tab {
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 0;
  }
  .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    color: #2d9f86;
    width: 3rem;
    height: 3rem;
  }
  .errorpagetext {
    color: red;
    font-size: xx-large;
    text-align: center;
    padding-top: 200px;
  }
  .home_EKGreading svg {
    width: 100%;
  }
  .bg_darkblue {
    background: linear-gradient(90deg, #142a39 0%, #3e5c70 100%);
    border-radius: 4px;
  }
  .weight_600 {
    font-weight: 600;
    letter-spacing: 0.25px;
  }
  .weight_500 {
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 16px;
  }
  .weight_600 {
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 16px;
  }
  .weight_400 {
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 16px;
  }
  .max-width150 {
    max-width: 150px;
  }
  .min-width163 {
    min-width: 163px;
  }
  .min-width127 {
    min-width: 127px;
  }
  .min-width104 {
    min-width: 104px;
  }
  .min-width72 {
    min-width: 72px;
  }
  .height_350 {
    height: 350px;
  }
  .btn_green {
    background: #6bc077 !important;
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    border-color: #6bc077 !important;
    color: #ffffff !important;
  }
  .btn_white_fill {
    background: #ffffff !important;
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    border-color: #ffffff !important;
    color: #000000 !important;
  }
  .btn_green_light {
    background: rgba(45, 159, 134, 0.1) !important;
    border-color: rgba(45, 159, 134, 0.1) !important;
    border-radius: 4px !important;
    color: #067f6f !important;
  }
  .btn_teal_two {
    background: #415b6a !important;
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    border-color: #6bc077 !important;
    color: #ffffff !important;
    border-radius: 4px;
  }
  .bg_red {
    background: #f25f5f !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    border-color: #f25f5f !important;
    color: #ffffff !important;
  }
  .bg_red_darker {
    background: #cc3d3f !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    border-color: #cc3d3f !important;
    color: #ffffff !important;
  }
  .bg_white {
    background: #ffffff !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1) !important;
    color: #142a39 !important;
    border-color: #fff !important;
  }
  .left_15 {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .left_only_15 {
    position: absolute;
    left: 10px;
  }
  .f30 {
    font-size: 24px !important;
  }
  .fwhite {
    border-color: #fff !important;
    color: #fff !important;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent !important;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background-color: #f6f8f7;
  }
  .table thead th {
    font-weight: 600;
    border-top: 0;
    border-bottom: 1px solid #dee2e6;
  }

  /*admin styles starts here*/
  .border_bot {
    border-bottom: 1px solid #b7c4d2 !important;
  }
  .height360 {
    min-height: 360px;
  }
  .height500 {
    max-height: 500px;
  }

  .maxheight523 {
    max-height: 523px;
  }

  .height171 {
    min-height: 171px;
  }
  .circle_b {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .circle_light_teal {
    background: #2d9f86;
  }
  .circle_light_blue {
    background: #c4e5ec;
  }
  .circle_light_purple_premium {
    background: #885fa5;
  }
  .circle_grey {
    background: #415b6a;
  }
  .circle_red {
    background: #cc3d3f;
  }
  .circle_yellow {
    background: #fdc246;
  }
  .circle_orange {
    background: #ff7300;
  }
  .card-title {
    color: #415b6a;
    letter-spacing: 2.5px;
    line-height: 16px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .small_head {
    font-size: 12px;
    font-weight: 600;
  }
  .big_font {
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
  }
  .medium_font {
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
  }
  .bg_lightgreen {
    background: #ddf1ee;
  }
  .card-title .MuiSvgIcon-root {
    font-size: 1.8rem;
  }
  .all_appointments .MuiTableCell-body {
    padding: 15px 16px !important;
    font-size: 16px;
  }
  .box_shade {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
  }
  .bg_white1 {
    background: #fff;
  }
  .all_appointments .MuiSelect-icon {
    color: #babfbd;
  }
  .label_grey .MuiChip-label {
    border-radius: 99px;
    font-size: 14px !important;
    background: #e3e5e7;
    padding: 1px 12px;
  }
  .MuiTableCell-head {
    font-size: 16px;
  }
  .MuiTablePagination-caption {
    font-size: 16px !important;
  }
  .not-rounded .MuiSelect-select {
    border-radius: 0;
  }

  .min_height50 {
    min-height: 50px;
  }
  .home_scroll {
    overflow-y: auto;
    height: calc(100vh - 115px);
  }
  .admin_home_scroll {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    height: calc(100vh - 200px);
  }
  //.height-100vh{
  //  //height: calc(100%);
  //  /*margin-bottom: 19px;*/
  //}
  //.calendar-container.full_calendar{
  //  min-width: fit-content;
  //}
  .dashboard_calendar .rbc-calendar .rbc-time-view {
    min-width: auto;
  }
  .rbc-calendar .rbc-time-view {
    min-width: 1500px;
  }
  .rbc-month-row {
    min-height: 150px;
  }
  .rbc-month-view {
    overflow-y: auto;
    min-width: 1600px;
  }
  .rbc-row.rbc-month-header {
    position: sticky;
    top: 0;
    z-index: 5;
    //display: block;
  }
  /*.rbc-month-view .rbc-date-cell {*/
  /*  min-width:270px;*/
  /*  padding: 0 !important;*/
  /*}*/
  .rbc-month-row .rbc-row-content .rbc-row:nth-of-type(2) .rbc-row-segment {
    // width:240px !important;
  }
  /*.rbc-event.doctors_month {*/
  /*  width:250px !important;*/
  /*}*/
  .rbc-time-content {
    background: #f0f8f5;
  }
  .col {
    flex-flow: column;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-box;
    display: -moz-box;
    display: flex;
  }
  .col .content {
    flex-grow: 1;
  }
  .dotee {
    height: calc(100vh - 160px);
    overflow-y: auto;
  }
  .noappt_content {
    margin-top: 20%;
  }
  .btn-outline-secondary,
  .btn-outline-secondary:hover,
  .btn-outline-secondary:focus {
    border-color: #2d9f86 !important;
    color: #2d9f86 !important;
    background-color: transparent !important;
    font-weight: 700 !important;
    font-size: 14px !important;
  }
  .btn-outline-primary,
  .btn-outline-primary:hover,
  .btn-outline-primary:focus {
    border-color: #142a39 !important;
    color: #142a39 !important;
    background-color: transparent !important;
    font-weight: 700 !important;
    font-size: 14px !important;
  }
  .btn-outline-white,
  .btn-outline-white:hover,
  .btn-outline-white:focus {
    border-color: #ffffff !important;
    color: #ffffff !important;
    background-color: transparent !important;
    font-weight: 700 !important;
    font-size: 14px !important;
  }
  .height-100v {
    height: calc(100vh - 20px);
  }
  .noekgtext {
    display: inherit;
    text-align: center;
    vertical-align: middle;
    color: red;
  }
  .br_rad {
    border-radius: 15px !important;
  }
  .status_purple {
    min-width: 120px;
    border: 2px dashed #885fa5 !important;
    color: #885fa5 !important;
    background: #f6f3f8 !important;
  }
  .status_green {
    min-width: 120px;
    border: 2px dashed #2d9f86 !important;
    color: #2d9f86 !important;
    background: #eaf5f3 !important;
  }
  .status_green_completed {
    min-width: 120px;
    border: 1.5px solid#2D9F86 !important;
    color: #142a39 !important;
    background: transparent !important;
    text-transform: uppercase !important;
    font-family: 'Work Sans' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-align: center;
  }
  .status_inprogress_dotted {
    min-width: 120px;
    border: 1.5px dashed #babfbd;
    color: #142a39 !important;
    background: transparent !important;
    text-transform: uppercase !important;
    font-family: 'Work Sans' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-align: center;
  }
  .status_orange_closed {
    min-width: 120px;
    border: 1.5px solid#F78C4E !important;
    color: #142a39 !important;
    background: transparent !important;
    text-transform: uppercase !important;
    font-family: 'Work Sans' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-align: center;
  }
  .status_red_canceled {
    min-width: 120px;
    border: 1.5px solid#CC3D3F !important;
    color: #142a39 !important;
    background: transparent !important;
    text-transform: uppercase !important;
    font-family: 'Work Sans' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-align: center;
  }
  .status_green_scheduled {
    min-width: 120px;
    border: 1.5px dashed #067f6f !important;
    color: #142a39 !important;
    background: transparent !important;
    text-transform: uppercase !important;
    font-family: 'Work Sans' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-align: center;
  }
  .status_gray_inprogress {
    min-width: 120px;
    border: 1.5px dashed #babfbd !important;
    color: #142a39 !important;
    background: transparent !important;
    text-transform: uppercase !important;
    font-family: 'Work Sans' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-align: center;
  }
  .status_gray_reportgenerated {
    min-width: 120px;
    border: 1.5px dashed #babfbd !important;
    color: #142a39 !important;
    background: transparent !important;
    text-transform: uppercase !important;
    font-family: 'Work Sans' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-align: center;
  }
  .status_gray_doctorreviewstarted {
    min-width: 120px;
    border: 1.5px solid #142a39 !important;
    color: #142a39 !important;
    background: transparent !important;
    text-transform: uppercase !important;
    font-family: 'Work Sans' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-align: center;
  }
  .status_orange {
    min-width: 120px;
    border: 2px dashed #f78c4e !important;
    color: #f78c4e !important;
    background: #feefef !important;
    text-transform: uppercase !important;
    font-family: 'Work Sans' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-align: center;
  }
  .status_red {
    min-width: 120px;
    border: 2px dashed #f25f5f !important;
    color: #f25f5f !important;
    background: #fef3ed !important;
    text-transform: uppercase !important;
    font-family: 'Work Sans' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-align: center;
  }
  .status_scheduled {
    min-width: 120px;
    border: 2px dashed #885f85 !important;
    color: #885f85 !important;
    background: #f6edfe !important;
    text-transform: uppercase !important;
    font-family: 'Work Sans' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-align: center;
  }
  /*IE -10 fix*/
  .rbc-time-column .rbc-timeslot-group,
  .rbc-time-slot {
    flex: 1 1 auto;
  }
  /*IE -10 classstyle mui*/
  .css_mui_flex_box {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .css_mui_flex_direction_row {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .css_mui_flex_direction_column {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .bars .MuiSvgIcon-root {
    color: #2d9f86;
    font-size: 38px;
  }
  .logo_text {
    letter-spacing: 0.25px;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
  }
  p {
    line-height: 1.3;
  }
  .boxshadow {
    box-shadow: 0 2px 0.2rem #0000007a !important;
    -moz-box-shadow: 0 2px 0.2rem #0000007a !important;
    -webkit-box-shadow: 0 2px 0.2rem #0000007a !important;
  }
  .boxshadow1 {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 2px rgba(0, 0, 0, 0.12),
      0px 1px 2px rgba(0, 0, 0, 0.24) !important;
    -moz-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 2px rgba(0, 0, 0, 0.12),
      0px 1px 2px rgba(0, 0, 0, 0.24) !important;
    -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 2px rgba(0, 0, 0, 0.12),
      0px 1px 2px rgba(0, 0, 0, 0.24) !important;
    border-radius: 3px !important;
  }
  .minheight_57 {
    min-height: 57px;
  }
  .maxheight_45 {
    max-height: 45px;
  }
  .overflow_modal {
    height: calc(100vh - 350px);
    overflow-y: auto;
  }
  .btn.disabled,
  .btn:disabled {
    opacity: 0.3 !important;
  }
  .swal-icon--success__line--tip {
    left: 11px;
    top: 46px;
  }
  .swal-icon--success__line--long {
    right: 3px;
    top: 38px;
  }
  .f20 {
    font-size: 20px;
  }
  .swal-icon.swal-icon--success + div.swal-title,
  .swal-icon.swal-icon--success + div.swal-title + div.swal-text,
  .swal-icon.swal-icon--success + div.swal-title + div.swal-text + div.swal-footer {
    text-align: center !important;
  }
  .btn.btn-back .MuiSvgIcon-root {
    font-size: 2rem !important;
  }
  .user_img {
    background: url('../../assets/images/img_user.png') no-repeat center center #000;
    height: 100%;
    width: 100%;
  }
  .OTPublisherContainer {
    border: 0px solid #545454;
    border-radius: 0px;
  }
  .font_white {
    color: #fff;
  }
  //chat window
  .f40 {
    font-size: 40px;
  }

  img {
    max-width: 100%;
  }
  .inbox_people {
    background: #f8f8f8 none repeat scroll 0 0;
    float: left;
    overflow: hidden;
    width: 40%;
    border-right: 1px solid #c4c4c4;
  }
  .inbox_msg {
    border: 1px solid #c4c4c4;
    clear: both;
    overflow: hidden;
  }
  .top_spac {
    margin: 20px 0 0;
  }

  .recent_heading {
    float: left;
    width: 40%;
  }
  .srch_bar {
    display: inline-block;
    text-align: right;
    width: 60%;
  }
  .headind_srch {
    padding: 10px 29px 10px 20px;
    overflow: hidden;
    border-bottom: 1px solid #c4c4c4;
  }

  .recent_heading h4 {
    color: #05728f;
    font-size: 21px;
    margin: auto;
  }
  .srch_bar input {
    border: 1px solid #cdcdcd;
    border-width: 0 0 1px 0;
    width: 80%;
    padding: 2px 0 4px 6px;
    background: none;
  }
  .srch_bar .input-group-addon button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    padding: 0;
    color: #707070;
    font-size: 18px;
  }
  .srch_bar .input-group-addon {
    margin: 0 0 0 -27px;
  }

  .chat_ib h5 {
    font-size: 15px;
    color: #464646;
    margin: 0 0 8px 0;
  }
  .chat_ib h5 span {
    font-size: 13px;
    float: right;
  }
  .chat_ib p {
    font-size: 14px;
    color: #989898;
    margin: auto;
  }
  .chat_img {
    float: left;
    width: 11%;
  }
  .chat_ib {
    float: left;
    padding: 0 0 0 15px;
    width: 88%;
  }

  .chat_people {
    overflow: hidden;
    clear: both;
  }
  .chat_list {
    border-bottom: 1px solid #c4c4c4;
    margin: 0;
    padding: 18px 16px 10px;
  }
  .inbox_chat {
    height: 550px;
    overflow-y: scroll;
  }

  .active_chat {
    background: #ebebeb;
  }

  .incoming_msg_img {
    display: inline-block;
    width: 6%;
  }
  .received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    min-width: 60px;
    max-width: 80%;
  }
  .received_msg p {
    background: #fff none repeat scroll 0 0;
    border-radius: 4px;
    color: #142a39;
    font-size: 14px;
    margin-bottom: 15px;
    padding: 12px 10px 12px 8px;
    width: 100%;
    border: 1px solid #cccccc54;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02),
      0px 1px 12px rgba(0, 0, 0, 0.12);
    line-height: 24px;
  }
  .time_date {
    color: #3c3c43, 60%;
    display: block;
    font-size: 12px;
    // margin: 8px 0 0;
  }
  .incoming_time_date {
    color: #000;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
  }
  // .received_withd_msg { width: 57%;}
  .mesgs {
    height: 330px;
    overflow-y: auto;
  }

  .videoclls {
    flex: 1;
    min-height: 450px;
    background: rgba(20, 42, 57, 0.2) !important;
  }

  .sent_msg p {
    background: #067f6f none repeat scroll 0 0;
    border-radius: 4px;
    font-size: 14px;
    margin: 0;
    color: #fff;
    padding: 12px 5px 12px 8px;
    width: 100%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02),
      0px 1px 12px rgba(0, 0, 0, 0.12);
    line-height: 24px;
  }
  .outgoing_msg {
    overflow: hidden;
    // margin:10px 0 10px;
  }
  .sent_msg {
    float: right;
    min-width: 60px;
    max-width: 80%;
  }
  .input_msg_write input {
    background: #fff;
    border: medium none;
    color: #000;
    font-size: 15px;
    height: 48px;
    width: 100%;
  }
  .mesgs {
    background: #f2f4f8;
  }

  .type_msg {
    background: #ffffff;
    box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 4px 4px;
    background: #fff;
    padding: 5px 0;
  }
  .msg_send_btn {
    background: transparent;
    border: medium none;
    color: #142a39;
    cursor: pointer;
    font-size: 17px;
    height: 42px;
  }
  .messaging {
    padding: 0 0 50px 0;
  }
  .msg_history {
    overflow-y: auto;
    // display: inline-block;
  }
  .mesgstyping {
    background: #f2f4f8;
  }
  .height_50 {
    height: 50px;
  }
  .btn-outline-danger {
    color: #fff !important;
    border-color: #cc3d3f;
    background: #cc3d3f !important;
  }
  .chat_head {
    border-bottom: 1px solid #ddd;
  }
  .f16 {
    font-size: 16px;
  }
  .f12 {
    font-size: 12px;
  }
  .mxwidth_250 {
    max-width: 250px;
  }
  .set_height {
    min-height: calc(100vh - 100px);
  }
  .set_height_no_ekg {
    min-height: 1066px;
  }
  .set_height_pdf {
    min-height: 1066px;
    max-height: 1066px;
    overflow-y: auto;
  }
  .set_height_patientdetails_Appointments {
    min-height: 1066px;
    max-height: 1066px;
    overflow-y: auto;
  }

  .set_height_patientdetails {
    min-height: calc(100vh - 100px);
  }
  .panel_head {
    background: #142a39;
    border-radius: 4px 4px 0px 0px;
    height: 75px;
  }
  .chat_panel_head_white {
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08),
      0px 1px 12px rgba(0, 0, 0, 0.04);
    height: 72px;
  }
  .width110 {
    width: 110px;
  }
  .videomodal {
    // display: inline-flex;
    // width: 100vw;
    // height: 100vh;
    // margin-top: -98px;
    // position: absolute;
    // margin-top: calc(-100vh + 98px / 2);
    // margin-right: calc(-100vw / 2 + 500px / 2);
    // height: auto;
    min-height: 100vh;
    border-radius: 0;
  }
  .alivecor_img {
    background: url('../../assets/images/alivecorlogo.png') no-repeat center center;
    height: 10px;
    width: 62px;
  }

  .Mui-error {
    .MuiSelect-root {
      border-color: #ff0000 !important;
    }
  }
  //chat end

  @media (min-width: 1025px) {
    .home_notes {
      min-height: calc(100vh - 850px);
    }
    .appt_notes {
      min-height: calc(100vh - 770px) !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .right_container {
      min-width: 100%;
    }

    .width_330 {
      min-width: 200px !important;
      max-width: 200px !important;
    }
    .countBoxItem .count {
      width: 50px !important;
      height: 50px !important;
      font-size: 28px !important;
      line-height: 50px !important;
    }
    .bs-stepper-header {
      position: relative !important;
      left: -35px !important;
    }
    .bs-stepper .step-trigger {
      width: 110px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .right_container {
      min-width: 100%;
    }

    .width_330 {
      min-width: 200px !important;
      max-width: 200px !important;
    }
  }
}
